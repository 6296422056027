import * as React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { 
  Box,
  Heading,
} from '@chakra-ui/react'
import { SliceZone } from '@prismicio/react';
import { components } from '../components/Slices';
import Seo from '../components/Seo'


const PageTemplate = ({ data, location }) => {
  
  const pageContent = data.prismicPage.data;

  return (
    <Layout
        pageLocation={location}
        headerImage={pageContent.header_image?.localFile}
        headerImageAlt={pageContent.header_image?.alt}
    >
      <Seo title={pageContent.seo_title.text} description={pageContent.meta_description.text} />
      <Box my="16">
          <Heading as="h2" variant="h2" textAlign="center">
            {pageContent.page_title}
          </Heading>
      </Box>
        
       <Box>
           <SliceZone
            slices={pageContent.body}
            components={components}
            />
       </Box>

    </Layout>
  )
}

export const query = graphql`
query PageQuery($uid: String!)
{
    prismicPage(uid: {eq: $uid}) {
        uid
        data {
          page_title
          seo_title {
            text
          }
          meta_description {
            text
          }
          header_image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
              }
            }
          }
          body {
            ... on PrismicPageDataBodyFullWidthImage {
              id
              slice_type
              primary {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyFullWidthText {
              id
              slice_type
              primary {
                content {
                  richText
                }
              }
            }
            ... on PrismicPageDataBodyRightAlignedImage {
              id
              slice_type
              primary {
                content {
                  richText
                }
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
                    }
                  }
                }
              }
            }
            ... on PrismicPageDataBodyLeftAlignedImage {
              id
              slice_type
              primary {
                image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
                    }
                  }
                }
                content {
                  richText
                }
              }
            }
          }
        }
      }
    }   
`

export default PageTemplate
